<template>
    <div class="py-[5%] px-[6%]">
        <h2 class="text-[38px] font-[700]">Transform the way you hire</h2>
        <div class="grid grid-cols-3 gap-8">
            <div class="shadow-md rounded-md px-[3%] py-[5%] text-left my-[10%]">
                <font-awesome-icon class="text-[#3361FF] text-[40px] mb-6" :icon="['far', 'file-lines']" />
                <h1 class="text-[#3361FF] text-[14px] uppercase font-[700]">Hire faster</h1>
                <h1 class="text-[#292A2D] text-[22px] font-[600] my-2">Hire 90% faster</h1>
                <p class="text-[100%] font-[500] text-[#292A2D] mt-6">
                    Assessments are automatically graded by our powerful AI to save you time and provide accurate scores for each of your candidates. Top performers are highlighted by score and with
                    trophies and badges.
                </p>
            </div>
            <div class="shadow-md rounded-md px-[3%] py-[5%] text-left my-[10%]">
                <font-awesome-icon class="text-[#3361FF] text-[40px] mb-6" :icon="['fab', 'react']" />
                <h1 class="text-[#3361FF] text-[14px] uppercase font-[700]">HIRE SMARTER</h1>
                <h1 class="text-[#292A2D] text-[22px] font-[600] my-2">Predict performance</h1>
                <p class="text-[100%] font-[500] text-[#292A2D] mt-6">
                    Surface the most qualified person based on how they perform tasks specific to your role. Test technical, non-technical, and soft skills in one assessment. GO PLATFORM learns what’s
                    most valuable to you, and identifies people with the skills to match.
                </p>
            </div>
            <div class="shadow-md rounded-md px-[3%] py-[5%] text-left my-[10%]">
                <font-awesome-icon class="text-[#3361FF] text-[40px] mb-6" :icon="['fas', 'user-check']" />
                <h1 class="text-[#3361FF] text-[14px] uppercase font-[700]">HIRE FAIRER</h1>
                <h1 class="text-[#292A2D] text-[22px] font-[600] my-2">Reduce bias</h1>
                <p class="text-[100%] font-[500] text-[#292A2D] mt-6">
                    Instead of screening people out, make hiring inclusive by testing everyone that applies for your role. Do away with relying on resumes to find the best candidates and reduce human
                    biases that can be introduced in the hiring process.
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "TransformYourHire",
    data() {
        return {};
    },
};
</script>
