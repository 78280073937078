<template>
    <div class="tabs">
        <ul class="tabs__header">
            <li v-for="title in tabTitles" :key="title" :class="{ selected: title == selectedTitle }" @click="selectedTitle = title">
                <img loading="lazy" decoding="async" :src="require(`@/${title}`)" width="35" height="35" />
            </li>
        </ul>
        <slot> </slot>
    </div>
</template>

<script>
import { ref, provide } from "vue";
export default {
    name: "TabsWrapper",
    setup(props, { slots }) {
        const tabTitles = ref(slots.default().map((tab) => tab.props.title));
        const selectedTitle = ref(tabTitles.value[0]);

        provide("selectedTitle", selectedTitle);

        return {
            selectedTitle,
            tabTitles,
        };
    },
    /* data () {
        return {
          selectedIndex: 0, // the index of the selected tab,
          tabs: []         // all of the tabs
        }
      },
      created () {
        this.tabs = this.$children
      }*/
};
</script>
<style scoped>
.tabs {
    width: 100%;
    margin: 0 auto;
    background-color: #fff;
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    grid-column-start: 3;
    grid-row: 2 / span 4;
    margin-bottom: -5%;
    box-shadow: 0px 2px 5px rgb(0 0 0 / 3%);
    border-radius: 5px;
}
.tabs__header {
    margin-bottom: -1px;
    list-style: none;
    padding: 0;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    width: 100%;
    font-weight: 700;
    font-size: 14px;
}
.tabs__header li {
    width: 100%;
    text-align: center;
    padding: 10px 0px;
    cursor: pointer;
    border: 1px solid transparent;
    transition: 0.4s all ease-out;
}
.tabs__header li:hover {
    background-color: #f4f7fe;
}
.tabs__header li.selected {
    background-color: #fff;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    border: 1px solid #ddd;
    border-top: 3px solid #ddd;
    border-bottom: 1px solid #fff;
}
</style>
