<template>
    <div>
        <h3>{{ $t("Online Users") }}</h3>
        <ul>
            <li v-for="user in users" :key="user.id">
                {{ user.username }}
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    props: {
        users: Array,
    },
};
</script>
