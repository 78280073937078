<template>
    <button class="viewbtn w-[90%] ml-[5%] mr-[5%] h-fit mt-[20%] py-2 px-1">{{ $t("Upgrade now") }}</button>
</template>

<script>
export default {
    name: "UpgradeBtn",
    data() {
        return {};
    },
    methods: {},
    mounted() {},
};
</script>

<style lang="scss" scoped>
.viewbtn {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 28px;
    border: none;
    border-radius: 15px;
    color: #fff;
    background: linear-gradient(135deg, #868cff 0%, #2196f3 100%);
}

.viewbtn:hover {
    box-shadow: inset 0px 0px 8px 2px rgb(134, 140, 255, 0.5);
    background: white;
    // border: #868cff 1px solid;
    color: #868cff;
}
</style>
