<template>
    <div class="candidate">
        <div class="score-head">
            <div>
                <h3>{{ $t("Total Spent") }}</h3>
                <span class="on-track">$682.5</span>
            </div>
            <img loading="lazy" decoding="async" src="../assets/Images/icons/bar-chart-icon.svg" alt="" />
        </div>

        <div class="chart-container">
            <HistogramVue :histogramData="this.histogramData" :chartWidth="610" :chartHeight="350" :showLabel="true" />
            <!-- <svg class="chart-svg"></svg> -->
        </div>
    </div>
</template>

<script>
// import * as d3 from "d3";
import HistogramVue from "../charts/Histogram.vue";
export default {
    name: "TotalSpent",
    components: { HistogramVue },
    data() {
        return {
            histogramData: [
                { label: "january", value: 400 },
                { label: "February", value: 350 },
                { label: "March", value: 53 },
                { label: "April", value: 200 },
                { label: "May", value: 35 },
                { label: "June", value: 410 },
                { label: "July", value: 45 },
                { label: "Aout", value: 310 },
                { label: "September", value: 500 },
                { label: "October", value: 200 },
                { label: "November", value: 35 },
                { label: "December", value: 42 },
            ],
        };
    },
};
</script>

<style scoped lang="scss">
.candidate {
    height: 350px;
    background-color: #fff;
    border-radius: 20px;
    margin: 2% 0 0 0;
}

.candidate > button {
    margin-top: 2%;
    margin-left: 83%;
}

.calendar-candidate > button:hover {
    box-shadow: 0px 0px 40px rgb(94 116 105 / 25%);
    text-decoration: none !important;
}

.score-head {
    display: grid;
    justify-items: baseline;
    align-items: center;
    grid-template-columns: 5fr 2fr;

    & > :first-child {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        text-align: left;

        h3 {
            color: #a3aed0;
            font-size: 14px;
            font-weight: 400;
        }
    }
}

.score-head > div {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: baseline;
    align-items: center;
}

.score-head > div > span {
    color: #1b2559;
}

.score-head > select {
    justify-self: end;
    color: #a3aed0;
    border: none;
    outline: none;
}

.score-head > select:focus {
    border: none;
    outline: none;
}

.score-head > img {
    justify-self: end;
    height: 33px;
    height: 33px;
}

.viewbtn {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 28px;
    border: none;
    letter-spacing: -0.02em;
    background: none;
    color: #2196f3;
}

.viewbtn:hover {
    text-decoration: underline #2196f3;
}

.on-track {
    font-family: "DM Sans";
    font-weight: 700;
    font-size: 34px;
    color: #1b2559;
}

.chart-container {
    width: 100%;
    height: 500px;
    /* Set the desired height for the chart */
    margin-top: 2%;
    margin-left: 0;
}

.chart-svg {
    width: 100%;
    height: 100%;
}
</style>
