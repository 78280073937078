<template>
    <div class="welcomeWindowWrapper" v-if="showWelcome">
        <div class="welcome-window">
            <h1>{{ $t("Welcome to go platform!") }} 🎉</h1>
            <p>{{ $t("Thank you for choosing our app. We hope you enjoy using it.") }} 😊</p>
            <div class="buttons">
                <button @click="toggleWelcome">{{ $t("No Thanks.") }}</button>
                <button @click="startTour" class="tour">{{ $t("Start Tour") }} 🚀</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "WelcomeWindow",
    props: {
        startTour: Function,
        showWelcome: Boolean,
        toggleWelcome: Function,
    },
    methods: {
        noThanks() {
            // handle no thanks button click
        },
    },
};
</script>

<style scoped lang="scss">
.welcomeWindowWrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 20;
}

.welcome-window {
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 20px;
    text-align: center;
    border-radius: 20px;
}

.buttons {
    width: 70%;
    margin: 0 15%;
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
}

button {
    background-color: #fff;
    border: none;
    color: #007bff;
    padding: 10px 20px;
    margin-right: 10px;
    cursor: pointer;
    border-radius: 20px;
    transition: all 0.1s linear;
}

button:hover {
    background-color: #0069d9;
    color: #fff;
}

.tour {
    background-color: #007bff;
    transition: all 0.1s linear;
    color: #fff;

    &:hover {
        background: #fff;
        color: #007bff;
        border: 1px solid #007bff;
    }
}
</style>
