<template>
    <div class="tab-content" v-show="title == selectedTitle">
        <slot> </slot>
    </div>
</template>

<script>
import { inject } from "vue";
export default {
    name: "TabVue",
    props: ["title"],
    setup() {
        const selectedTitle = inject("selectedTitle");

        return {
            selectedTitle,
        };
    },
    data() {
        return {};
    },
};
</script>

<style scoped>
.tab-content {
    padding: 10% 8%;
    border-top: 1px solid #ddd;
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 10.5315px;
    line-height: 16px;
    color: #4d5e80;
}
</style>
